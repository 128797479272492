.ti_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ti_content {
  position: relative;
}

.ti_icon_container_mobile {
  display: none;
}

.lottie_star1 {
  position: absolute;
  top: -16px;
  left: -34px;
}

.lottie_star2 {
  position: absolute;
  top: -30px;
  left: -34px;
  transform: rotate(-74.72deg);
}

.ti_upper_text_container {
  font-size: 24px;
  font-weight: 600;
  line-height: 2.125rem;
  white-space: pre-wrap;
  padding-bottom: 1.5rem;
}

.ti_upper_text_container span {
  color: var(--secondary-text-color);
}

.ti_lower_text_container {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5rem;
  white-space: pre-wrap;
}

@media (max-width: 1080px) {
  .ti_icon_container_desktop {
    display: none;
  }

  .ti_icon_container_mobile {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
