.review_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 308px;
  height: 308px;
  background: #ffffff;
  margin: 0 10px;
  border: 1px solid transparent;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(
      to right,
      rgba(74, 190, 255, 0.5) 0%,
      rgba(196, 108, 255, 0.5) 37%,
      rgba(113, 64, 255, 0.5) 68%,
      rgba(51, 0, 255, 0.5) 97%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-radius: 30px;
}

.review_text_wrapper {
  display: flex;
  flex-direction: column;
  width: 85%;
  gap: 1rem;
}

.review_header {
  font-weight: 500;
  line-height: 1.375rem;
  white-space: pre-wrap;
}

.review_content {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.3125rem;
}

.review_user_wrapper {
  display: flex;
  width: 85%;
  margin-top: 1.25rem;
  align-items: center;
}

.user_icon {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  overflow: hidden;
}

.user_icon img {
  width: 100%;
  height: 100%;
}

.user_name {
  font-size: 12px;
  font-weight: 400;
}

.user_name span {
  color: #a7a7a7;
}
