.mynail_hero_background_products_desktop {
  background-size: auto;
}

.mynail_hero_purple,
.mynail_hero_nail_clipper,
.mynail_hero_pusher,
.mynail_hero_gel_lamp,
.mynail_hero_pink,
.mynail_hero_skyblue {
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
}

/* standard - width: 1280px, height: 739px */
.mynail_hero_background_products_desktop .mynail_hero_purple {
  width: 24.688vw;
  height: 45.196vh;
}

.mynail_hero_background_products_desktop .mynail_hero_nail_clipper {
  width: 13.131vw;
  height: 21.112vh;
}

.mynail_hero_background_products_desktop .mynail_hero_pusher {
  width: 20.427vw;
  height: 51.272vh;
}

.mynail_hero_background_products_desktop .mynail_hero_gel_lamp {
  width: 17.484vw;
  height: 25.324vh;
}

.mynail_hero_background_products_desktop .mynail_hero_pink {
  width: 19.801vw;
  height: 35.978vh;
}

.mynail_hero_background_products_desktop .mynail_hero_skyblue {
  width: 26.33vw;
  height: 45.194vh;
}
