.mynail_desktop_nav_container {
  width: 100%;
}

.mynail_mobile_nav_container {
  display: none;
  width: 100%;
}

.mynail_desktop_nav_container,
.mynail_mobile_nav_container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

@media (max-width: 1080px) {
  .mynail_desktop_nav_container {
    display: none;
  }
  .mynail_mobile_nav_container {
    display: flex;
  }
}
