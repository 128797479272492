.service_area_modal_container {
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  right: 0;
  bottom: 0;
  display: none;
  justify-content: center;
  width: 350px;
  height: 650px;
  border: 1px solid #efefef;
  border-radius: 24px;
  background: #fff;
  transition: opacity 0.3s ease;
}

.service_area_modal_container.show {
  display: flex;
}

.service_area_modal_content {
  width: 100%;
}

.service_area_modal_header {
  padding: 15px;
  text-align: center;
}

.service_area_modal_header h5 {
  font-size: 15px;
}

.service_area_search_container {
  margin: 20px 0px;
  width: 100%;
  position: relative;
}

.service_area_search_container input {
  border: 1px solid #EACCFF;
  width: 100%;
  height: 40px;
  font-size: 14px;
  padding: 0 40px 0 10px;
  border-radius: 8px;
  outline: none;
}

.service_area_search_container .search_icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.service_area_modal_text_container {
  display: flex;
  flex-direction: column;
  padding: 0px 20px 23px 20px;
}

.search_result_container {
  margin-top: 5px;
  padding-top: 5px;
  max-height: 450px;
  overflow-y: auto;
}

.search_result_item_container {
  height: 100%;
  margin-bottom: 15px;
}
.search_result_badge {
  padding: 5px 15px;
  border-radius: 30px;
  font-size: 12px;
  font-weight: bold;
}

.search_result_badge.available {
  color: #C46CFF;
  border: 1px solid transparent;
  background: #fff;
  background-clip: padding-box;
  position: relative;
}

.search_result_badge.available::before {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 30px;
  background: linear-gradient(270deg, rgba(74, 190, 255, 0.5) 21.27%, rgba(196, 108, 255, 0.5) 57.77%, rgba(113, 64, 255, 0.5) 89.27%, rgba(51, 0, 255, 0.5) 118.27%);
  z-index: -1;
}

.search_result_badge.unavailable {
  border: 1px solid #FFD2DD;
  color: #FF85A3;
}

.search_result_item_container .search_result_address_area {
  margin-top: 15px;
  margin-left: 10px;
  font-size: 14px;
}

.search_result_item_container .search_result_address {
  margin-bottom: 5px;
}

.search_result_item_container .search_result_address_type {
  color: #AAAAAA;
  margin-right: 10px;
}

.search_result_no_result {
  text-align: center;
  margin-top: 50px;
  font-size: 12px;
}

.service_area_modal_container button {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 46px;
  background: var(--secondary-text-color);
  border: none;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  border-radius: 0px 0px 24px 24px;
}

@media (max-height: 750px) {
  .service_area_modal_container {
    height: 500px;
  }
  .search_result_container {
    max-height: 300px;
  }
}

@media (max-width: 389px) {
  .service_area_modal_container {
    transform: translate(-49%, -50%);
  }
}