.mynail_map_block {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.mynail_map_content_container {
  display: flex;
  align-items: center;
  gap: 5.625vw;
  padding: 5.375rem 0;
}

.map_container_mobile {
  display: none;
}

.map_text_top {
  font-size: 20px;
}

.map_text_top span {
  color: var(--tertiary-text-color);
  font-weight: 700;
}

.map_main_text {
  padding-top: 1rem;
  padding-bottom: 2.25rem;
  white-space: pre-wrap;
}

.map_main_text h1 {
  font-size: 36px;
}

.purple {
  color: var(--secondary-text-color);
}

.deep_purple {
  color: var(--tertiary-text-color);
}

.search_map_button {
  border: none;
  background: none;
}

@media (max-width: 1080px) {
  .mynail_map_content_container {
    flex-direction: column;
    padding: 3.5rem 0;
  }

  .map_container_desktop {
    display: none;
  }

  .map_container_mobile {
    display: block;
  }

  .map_text_container {
    width: 100%;
    text-align: center;
  }

  .map_main_text {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    white-space: pre-wrap;
  }
}
