.feature_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 4.125rem;
  padding-bottom: 5rem;
}

.feature_upper_text_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 2rem;
  padding-bottom: 5.75rem;
}

.feature_upper_text_container p {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5626rem;
}

.feature_upper_text_container span {
  color: #be69ff;
}

.feature_upper_text_content_container p {
  font-weight: 400;
}

.feature_product_block {
  display: flex;
  width: 90vw;
}

.feature_cards_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 80px;
}

@media (max-width: 1080px) {
  .feature_upper_text_header_container {
    text-align: center;
    white-space: pre-wrap;
  }

  .feature_upper_text_header_container h1 {
    font-size: 24px;
    line-height: 2rem;
  }

  .feature_upper_text_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 2rem;
    padding-bottom: 5.75rem;
  }

  .feature_upper_text_content_container {
    white-space: pre-wrap;
    text-align: center;
  }

  .feature_upper_text_content_container p {
    font-size: 14px;
    font-weight: 300;
  }

  .feature_product_block {
    display: flex;
    width: 91.467vw;
  }
}
