.mynail_feature_card_container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.feature_card_img_container {
  position: relative;
  width: 630px;
  height: 386px;
  overflow: hidden;
  border-radius: 80px;
  box-shadow: 0 0 12px rgba(241, 220, 255, 0.5);
}

.feature_img_background {
  position: absolute;
  top: -6%;
  left: -6%;
  background-size: cover;
  background-position: center;
  height: 110%;
  width: 110%;
  transition: transform 0.3s ease;
}

.feature_card_text_icon_container {
  width: 30%;
}

@media (max-width: 1080px) {
  .mynail_feature_card_container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .feature_card_img_container {
    width: 70%;
    height: 280px;
    margin: 0px;
    border-radius: 30px;
    overflow: hidden;
  }
  .feature_card_text_icon_container {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 860px) {
  .feature_card_img_container {
    width: 343px;
    height: 210px;
  }
}
