.nav {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 6px 16px 0px hsla(253, 98%, 63%, 0.059);
  backdrop-filter: blur(100px);
  border-bottom: 1px solid #ffffff;
}

.nav_inner_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav_inner_container p {
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.nav_lt,
.nav_rt {
  display: flex;
  gap: 32px;
}

.nav_lt {
  padding-right: 64px;
}

.nav_rt {
  padding-left: 64px;
}
