.inquire_submit_modal_container {
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 150px;
  border: 1px solid #fff;
  border-radius: 24px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.6);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.inquire_submit_modal_container.show {
  opacity: 1;
}

.submit_modal_content {
  width: 100%;
  text-align: center;
}

.submit_modal_text_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 23px;
}

.submit_modal_text_container p {
  white-space: pre-wrap;
  font-size: 16px;
  font-weight: 400;
}

.inquire_submit_modal_container button {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 46px;
  background: var(--secondary-text-color);
  border: none;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
}
