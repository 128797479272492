.review_block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    to left,
    rgba(74, 190, 255, 0.06) 10%,
    rgba(198, 108, 255, 0.06) 67%,
    rgba(113, 64, 255, 0.06) 90%,
    rgba(51, 0, 255, 0.06) 137%
  );
}

.review_title_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 5.5rem;
}

.review_title_container span {
  color: var(--tertiary-text-color);
}

.review_content {
  padding-top: 1rem;
  line-height: 1.5625rem;
  overflow: hidden;
  white-space: pre-wrap;
}

.review_carousel_container {
  display: flex;
  position: relative;
  width: 100%;
  margin-top: 3.0625rem;
  margin-bottom: 5.875rem;
  overflow: hidden;
}

.review_carousel {
  display: flex;
  animation: 35s slide infinite linear;
}

.review_carousel_container:hover .review_carousel {
  animation-play-state: paused;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@media (max-width: 1080px) {
  .review_title_container {
    white-space: pre-wrap;
    text-align: center;
  }

  .review_title_container h1 {
    font-size: 24px;
  }

  .review_carousel_container {
    margin-bottom: 3.5rem;
  }
}
