.feature_header_text_container {
  font-size: 24px;
  font-weight: 600;
  line-height: 2rem;
  white-space: pre-wrap;
  padding-bottom: 1.5rem;
}

.feature_content_text_container {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5rem;
  white-space: pre-wrap;
}

.price {
  color: var(--secondary-text-color);
  text-decoration: line-through;
}

.mynail_text {
  font-size: 22px;
  font-weight: 700;
  color: var(--tertiary-text-color);
  line-height: 2rem;
}
