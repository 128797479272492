.mynail_footer_container {
  width: 100%;
  height: 354px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer_logo_info_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer_info_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: 2.5rem;
  padding-bottom: 2rem;
  line-height: 1.8rem;
}

.footer_bold {
  font-weight: 600;
}

.footer_line_desktop,
.footer_line {
  color: #bcbcbc;
}

.footer_sns_container {
  display: flex;
  gap: 1rem;
}

.icon_container {
  cursor: pointer;
}

.footer_navigation_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.footer_navigation_container a {
  text-decoration: none;
  color: var(--secondary-text-color);
  font-weight: 500;
  cursor: pointer;
}

@media (max-width: 1080px) {
  .mynail_footer_container {
    height: 379px;
    font-size: 12px;
  }

  .footer_info_wrapper {
    white-space: pre-wrap;
    text-align: center;
    padding-top: 2rem;
    line-height: 1.5rem;
  }

  .footer_line_desktop {
    display: none;
  }

  .footer_sns_container {
    display: flex;
    gap: 0.5rem;
  }
}
