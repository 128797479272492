.mynail_hero_block_container {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    to left,
    rgba(74, 190, 255, 0.05) 10%,
    rgba(198, 108, 255, 0.05) 67%,
    rgba(113, 64, 255, 0.05) 90%,
    rgba(51, 0, 255, 0.05) 137%
  );
}

.mynail_hero_background_mobile {
  display: none;
}

.mynail_hero_content_container {
  display: flex;
  justify-content: center;
  height: 100%;
}

.mynail_hero_content {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: calc(100% - 80px);
  margin-top: 80px;
}

.mynail_hero_phone {
  display: flex;
  position: absolute;
  max-width: 70vw;
  max-height: 100vh;
  aspect-ratio: 821 / 739;
  bottom: 0;
  margin-right: 30%;
}

.mynail_hero_phone > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.mynail_hero_slider {
  position: absolute;
  margin-top: 24.1%;
  margin-left: 39.6%;
  width: 25.8%;
  max-height: 59.8%;
  min-height: 59.8%;
  aspect-ratio: 223 / 473;
  border-radius: 42px;
  overflow: hidden;
  background-position: center;
  will-change: transform;
}

.mynail_hero_slider_list {
  display: flex;
  min-width: calc(100% * 7);
  width: 100%;
  height: 100%;
}

.screen {
  width: 100%;
  height: 100%;
  object-fit: contain;
  animation: autoRun 13.8s linear infinite;
}

.screen img {
  height: 100%;
}

@keyframes autoRun {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  14.5% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  16.67% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    animation-timing-function: step-end;
  }
  31.17% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  33.34% {
    -webkit-transform: translateX(-200%);
    transform: translateX(-200%);
    animation-timing-function: step-end;
  }
  47.84% {
    -webkit-transform: translateX(-200%);
    transform: translateX(-200%);
  }
  50.01% {
    -webkit-transform: translateX(-300%);
    transform: translateX(-300%);
    animation-timing-function: step-end;
  }
  64.51% {
    -webkit-transform: translateX(-300%);
    transform: translateX(-300%);
  }
  66.68% {
    -webkit-transform: translateX(-400%);
    transform: translateX(-400%);
    animation-timing-function: step-end;
  }
  81.18% {
    -webkit-transform: translateX(-400%);
    transform: translateX(-400%);
  }
  83.35% {
    -webkit-transform: translateX(-500%);
    transform: translateX(-500%);
    animation-timing-function: step-end;
  }
  97.85% {
    -webkit-transform: translateX(-500%);
    transform: translateX(-500%);
  }
  100% {
    -webkit-transform: translateX(-600%);
    transform: translateX(-600%);
  }
}

.mynail_hero_text_container {
  position: relative;
  margin-left: 52%;
  width: 100%;
  height: fit-content;
  z-index: 20;
}

.mynail_hero_header_text {
  position: relative;
  font-size: 38px;
  font-weight: 700;
}

.mynail_hero_header_text p {
  position: absolute;
  top: 0;
  left: 0;
  white-space: pre-wrap;
  background: linear-gradient(to right, #c46cff -30%, #6b46f4 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mynail_hero_header_text span {
  -webkit-text-fill-color: #4c4c4c;
}

.mynail_hero_sub_text {
  padding-top: 5.65em;
  font-size: 20px;
  padding-bottom: 2.6em;
}

.start_icon_container {
  width: fit-content;
  cursor: pointer;
}

/* mobile */
@media (max-width: 1080px) {
  .mynail_hero_background_desktop {
    display: none;
  }

  .mynail_hero_background_mobile {
    display: block;
  }

  .mynail_hero_content {
    flex-direction: column;
    height: calc(100% - 56px);
    margin-top: 56px;
  }

  .mynail_hero_phone {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 300vw;
    height: 66.006vh;
    aspect-ratio: 480 / 433;
    order: 2;
    margin: 0;
  }

  .mynail_hero_phone img {
    object-fit: cover;
    transform: translateX(-12px);
  }

  .mynail_hero_slider {
    position: absolute;
    margin-top: 12%;
    margin-left: 4.6%;
    width: auto;
    max-width: 34.6%;
    max-height: 60%;
    aspect-ratio: 130 / 276;
    border-radius: 20px;
    order: 1;
    transform: translateX(-12px);
  }

  .screen img {
    height: 100%;
    transform: translateX(0);
  }

  .mynail_hero_text_container {
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-bottom: 45.72vh;
    align-items: center;
    order: 1;
  }

  .mynail_hero_text_wrapper {
    position: relative;
    width: 89.067vw;
  }

  .mynail_hero_header_text {
    font-size: 30px;
    font-weight: 700;
  }

  .mynail_hero_header_text p {
    text-align: center;
    width: 100%;
  }

  .mynail_hero_sub_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5.56em;
    font-size: 16px;
    padding-bottom: 2.5em;
  }
}
