@font-face {
  font-family: "SUITE";
  src: url("./static/fonts/SUITE-Light.otf") format("opentype");
  font-weight: 300;
  font-style: light;
}

@font-face {
  font-family: "SUITE";
  src: url("./static/fonts/SUITE-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SUITE";
  src: url("./static/fonts/SUITE-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: medium;
}

@font-face {
  font-family: "SUITE";
  src: url("./static/fonts/SUITE-SemiBold.otf") format("opentype");
  font-weight: 600;
  font-style: semibold;
}

@font-face {
  font-family: "SUITE";
  src: url("./static/fonts/SUITE-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: bold;
}

:root {
  --primary-text-color: #484848;
  --secondary-text-color: #c46cff;
  --tertiary-text-color: #6c44fd;
  --font-primary: "SUITE", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-primary);
  color: var(--primary-text-color);
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-primary);
  font-weight: 700;
  font-size: 34px;
}

html,
body {
  height: 100%;
}

#root {
  min-height: 100%;
}

.mynail_nav {
  width: 100%;
}

.mynail_feature {
  height: 2rem;
}

.mynail_review,
.mynail_map,
.mynail_inquire {
  height: 1rem;
}
