.mynail_hero_purple {
  width: 56.091vw;
  height: 29.595vh;
}

.mynail_hero_nail_clipper {
  width: 45vw;
  height: 21.112vh;
}

.mynail_hero_gel_lamp {
  width: 43.984vw;
  height: 27.755vh;
}

.mynail_hero_skyblue {
  width: 70.925vw;
  height: 57.428vh;
}
