.inquire_input_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 0.25rem;
  padding-bottom: 1.25rem;
}

.placeholder_box {
  border: 1px solid transparent;
  border-radius: 8px;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(
      to right,
      rgba(74, 190, 255, 0.25) 0%,
      rgba(196, 108, 255, 0.25) 37%,
      rgba(113, 64, 255, 0.25) 68%,
      rgba(51, 0, 255, 0.25) 97%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.placeholder_box input {
  width: 100%;
  height: 50px;
  font-size: 12px;
  font-weight: 300;
  color: #acacac;
  outline: none;
  border: none;
  border-radius: 8px;
  padding-left: 1.375rem;
}

.submit_button {
  width: 100%;
  max-height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}

form button {
  width: 100%;
  border: none;
  outline: none;
  background: none;
  color: #fff;
  font-size: 16px;
}

.sumbit_button_background {
  width: 100%;
  height: 46px;
  background: linear-gradient(to right, #6c44fd 30%, #c16bff 100%);
  border-radius: 34px;
}

form p {
  position: absolute;
  color: #fff;
  font-size: 16px;
}

@media (max-width: 1080px) {
  .submit_button {
    padding-top: 1.125rem;
  }
}
