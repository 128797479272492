.inquire_block_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to left,
    rgba(74, 190, 255, 0.06) 10%,
    rgba(198, 108, 255, 0.06) 67%,
    rgba(113, 64, 255, 0.06) 90%,
    rgba(51, 0, 255, 0.06) 137%
  );
}

.inquire_text_container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 5.5rem;
  text-align: center;
  gap: 1.75rem;
  white-space: pre-wrap;
}

.inquire_text_container p {
  line-height: 1.525rem;
}

.inquire_content_container {
  display: flex;
  width: 82.5vw;
  margin-top: 2.5rem;
  margin-bottom: 5.5rem;
  gap: 9%;
  justify-content: center;
  align-items: center;
}

.inquire_image_container {
  height: 100%;
  max-width: 517px;
}

.inquire_image_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.inquire_form_container {
  width: 42%;
}

@media (max-width: 1080px) {
  .inquire_content_container {
    flex-direction: column;
  }

  .inquire_form_container {
    width: 100%;
    padding-top: 2.5rem;
  }
}
