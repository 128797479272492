.mobile_nav_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 56px;
  padding: 16px 16px;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 6px 16px 0px hsla(253, 98%, 63%, 0.059);
  border-bottom: 1px solid #ffffff;
}

.mobile_nav_container_background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(100px);
  z-index: -1;
}

.mobile_nav_logo_container {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile_nav_burger_container {
  border: none;
  background: none;
  cursor: pointer;
}

.mobile_nav_menu {
  position: fixed;
  display: flex;
  justify-content: center;
  height: 252px;
  margin-top: 56px;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
}

.mobile_nav_menu ul {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin-top: 1.75rem;
  list-style: none;
}

.mobile_nav_menu li {
  font-weight: 500;
  padding-bottom: 2rem;
  cursor: pointer;
}

.mobile_nav_menu.show {
  transform: translateY(0);
}
